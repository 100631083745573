<!--
  Required props
    * @cancel= Function
    * @confirm= Function
    * confirm-text-prefix= String
    * element-display-name= String

  Optional content props
    * info-box-text= String
    * info-box-text-title= String
    * title (obsolete since 2023/11/02)

  Optional styling props
    * activator-btn-color= String
    * activator-btn-class= String
    * activator-btn-style= String
    * as-text-button : Boolean
    * disabled : Boolean
    * deletion-title= String
    * delete-button-text= String
    * small-activator-btn : Boolean
    * plain-text-activator-btn : Boolean
-->
<template>
  <v-dialog :wxid="$options.name" v-model="dialog" max-width="var(--modal-window-narrow-width)">
    <template #activator="{ on }">
      <wx-btn-icon
        v-if="!styleAsTextButton()"
        v-on="on"
        :title="$t('common.delete')"
        :disabled="disabled"
        :small="smallActivatorBtn"
        :color="activatorBtnColor"
        :style="activatorBtnStyle"
        :class="activatorBtnClass"
        class="dialog-activator-btn"
      >
        <v-icon :small="smallActivatorBtn">mdi-delete</v-icon>
      </wx-btn-icon>
      <wx-btn-standard
        v-if="styleAsTextButton()"
        v-on="on"
        :title="$t('common.delete')"
        :disabled="disabled"
        :plain="plainTextActivatorBtn"
        :ripple="plainTextActivatorBtn"
        :small="smallActivatorBtn"
        :color="activatorBtnColor"
        :style="activatorBtnStyle"
        :class="activatorBtnClass"
        class="dialog-activator-btn"
        text
      >
        {{ $t("common.delete") }}
      </wx-btn-standard>
    </template>

    <v-card :wxid="$options.name" tag="article" class="wx-confirmation-dialog-card">
      <v-card-title tag="header">
        <v-icon :title="deletionTitle" class="mb-3 mb-md-4 mb-xl-6">mdi-delete-outline</v-icon>
        <h2 class="heading">
          {{ confirmTextPrefix }}
          <q>{{ elementDisplayName }}</q
          >?
        </h2>
      </v-card-title>
      <v-card-text v-if="infoBoxText">
        <p>
          <strong v-if="infoBoxTextTitle">{{ infoBoxTextTitle }} :</strong>
          {{ infoBoxText }}
        </p>
      </v-card-text>
      <v-card-actions tag="footer" class="d-flex flex-button-column-gap">
        <wx-btn-standard
          @click="dialog = false"
          :title="$t('deletionConfirmationDialog.cancelBtnHint')"
          class="cancel-btn flex-grow-1"
          large
        >
          {{ $t("common.cancel") }}
        </wx-btn-standard>
        <wx-btn-standard
          @click="$emit('confirm'), (dialog = false)"
          :title="$t('deletionConfirmationDialog.deleteBtnHint')"
          color="error"
          class="flex-grow-1"
          large
        >
          <fragment v-if="!isMobilePhone && deleteButtonText">
            {{ deleteButtonText }}
          </fragment>
          <fragment v-else>
            {{ $t("common.delete") }}
          </fragment>
        </wx-btn-standard>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import WxBtnStandard from "@/components/ui/WxBtnStandard";

export default {
  name: "DialogConfirmDeletion",

  components: {
    WxBtnIcon,
    WxBtnStandard,
  },

  props: {
    asTextButton: {
      type: Boolean,
      default: () => false,
    },
    deletionTitle: {
      type: String,
      default: null,
    },
    infoBoxTextTitle: {
      type: String,
      default: () => "",
    },
    infoBoxText: {
      type: String,
      required: true,
    },
    confirmTextPrefix: {
      type: String,
      required: true,
    },
    elementDisplayName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    plainTextActivatorBtn: {
      type: Boolean,
      default: false,
    },
    smallActivatorBtn: {
      type: Boolean,
      default: false,
    },
    activatorBtnClass: {
      type: String,
      default: () => "",
    },
    activatorBtnColor: {
      type: String,
      default: () => null,
    },
    activatorBtnStyle: {
      type: String,
      default: () => "",
    },
    deleteButtonText: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      buttonAccessKey: "x",
    };
  },
  methods: {
    styleAsTextButton() {
      return this.asTextButton;
    },
  },
  computed: {
    isMobilePhone() {
      const vbpName = this.$vuetify.breakpoint.name;
      let phoneBreakpoint = false;
      if (vbpName === "xs") {
        phoneBreakpoint = true;
      }
      return phoneBreakpoint;
    },
  },
};
</script>

<style lang="scss" scoped>
.wx-confirmation-dialog-card {
  &.v-card {
    position: relative;
    max-width: var(--modal-window-medium-width);
    padding: var(--dialog-padding);
    background-color: var(--color-element-layer2);
    border-radius: var(--border-radius-lg);
    opacity: 1;

    ::v-deep .v-icon {
      font-size: var(--font-size-h1);
    }
    .v-card__text {
      font-size: var(--font-size-norm);
    }
    .heading,
    p {
      text-align: center;
    }

    &,
    .v-icon,
    .v-card__text,
    .v-card__actions,
    .v-expansion-panel-content {
      color: var(--color-text-theme);
    }

    .v-card {
      // Card Header
      &__title {
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
        padding-bottom: var(--dialog-padding);
        word-break: break-word;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
